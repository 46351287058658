/* You can add global styles to this file, and also import other style files */

@import '~swiper/swiper-bundle';

html, body { 
    height: 100%; 
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    min-width: 1200px;
    width: 100%;
    
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



/*--responsive--*/

@media only screen and (max-width: 768px) {
    html, body{
        min-width: unset;
    }
    .btn-alarm, .btn-messager{
        width: 2.5rem !important;
        height: 2.5rem !important;
        .material-icons{
            font-size: 1.2rem !important;
        }
        
    }
    .btn-alarm{
        bottom: 0.5rem !important;
        left: 0.5rem !important;
    }
    .btn-messager{
        bottom: 0.5rem !important;
        right: 0.5rem !important;
    }
    .content-info{
        padding: 1rem 0;
    }
    .divider{
        width: 100% !important;
        height: 1px !important;
    }
    .right-col, .left-col{
        padding: 0 !important;
        width: 100%;
    }
    .icon-box{
        // width: 3.5rem !important;
        img{
            width: 2.5rem !important;
        }
    }
}